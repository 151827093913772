import React from 'react';

const Bar = ({ splash, fontName }) => {
	const barStyle = {
		position: "absolute",
		textAlign: "center",
		fontWeight: "400",
		opacity: 0.5,
		fontSize: "1em",
		bottom: "0px",
		height: "2.0em",
		width: "100%",
		display: "flex",
		flexDirection: "columns",
		justifyContent: "space-between",
	};
	return (
		<div style={barStyle}>
			<div className="padded-left">
				{splash ? splash.folder : ""} / {fontName}
			</div>
			<div className="padded-right">
				<a href="https://discord.gg/WStut4A4ma">Discord</a> v1.4
			</div>
		</div>
	);
};

const TopBar = ({ showSettings }) => {
	const barStyle = {
		position: "absolute",
		textAlign: "center",
		fontWeight: "400",
		fontSize: "1.0em",
		top: "0.5em",
		opacity: 0.5,
		zIndex: "9",
		height: "1.0vw",
		width: "100%",
		display: "flex",
		flexDirection: "columns",
		justifyContent: "space-between",
	};
	return (
		<div style={barStyle}>
			<div></div>
			<div className="padded-right">
				<a onClick={() => showSettings()} href="#">
					Tweaks
				</a>
			</div>
		</div>
	);
};

export  {TopBar, Bar}
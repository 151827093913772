import React, { useRef, useState, useEffect } from 'react';
import {loadOr, toBool, time, save } from "./Utility";
import { useTweaks } from 'use-tweaks';
import Greeting from "./Greeting";

export default function Clock() {
	const ref = useRef(null);
	const tweaks = useTweaks("clock", {
		size: { value: parseFloat(loadOr("clock", "size", "5")), min: 1, max: 10 },
		shadow: toBool(loadOr("clock", "shadow", "true")),
		title: toBool(loadOr("clock", "title", "true")),
		opacity: { value: parseFloat(loadOr("clock", "opacity", "1")), min: 0, max: 1 },
		color: loadOr("clock", "color", "#fff"),
		greeting: toBool(loadOr("clock", "greeting", "true"))
	});
	const [showBanner, setShowBanner] = useState(tweaks.title);
	const [timeState, setTimeState] = useState(time());

	const updateClock = () => {
		setTimeState(time());
		setTimeout(updateClock, 1000);
	}

	const style = {
		fontWeight: "800",
		color: tweaks.color,
		opacity: tweaks.opacity,
		fontSize: tweaks.size + "em",
		textShadow: tweaks.shadow ? "0px 0px 2px #000" : "0px 0px 0px #000",
	};
	useEffect(() => {
		setTimeout(() => {
			setShowBanner(false);
		}, 1000);
		setTimeout(updateClock, 1000);
	});
	useEffect(() => {
		Object.keys(tweaks).forEach(k => {
			save("clock", k, tweaks[k])
		})
	}, [tweaks])
	return (
		<div id="centering">
			<div id="clock" ref={ref} style={style}>
				{showBanner ? "8bitdash" : timeState}
				{tweaks.greeting ? <Greeting /> : null}
			</div>
		</div>
	);
};

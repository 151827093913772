// --- components start here
import React, { useState, useEffect } from 'react';

export default function Greeting() {
	const greetings = () => {
		const date = new Date();
		let hours = date.getHours();
		let ampm = hours >= 12 ? "pm" : "am"
		console.log(hours)
		if (ampm === "pm") {
			if (hours >= 23) {
				return ([
					"Night-owl mode activated",
					"Sleep is for the weak",
					"Good night",
					"Have a good rest",
					"Late nights are for coding",
					"I'm your fortune cookie",
					"The agent takes over",
					"Who said nights are for sleep",
					"Maybe go to bed early today",
					"Time to recharge for tomorrow",
					"Sweet dreams are made of this",
					"May the moon guide you",
					"Let the stars be your guide",
					"Sleep tight, don't let the bed bugs bite"])
			} else if (hours >= 20) {
				return ([
					"Time to unwind",
					"Time to ponder on the day",
					"See you space cowboy",
					"Good night",
					"Relaxation mode activated",
					"Unwind with a good book",
					"Let the evening calm you",
					"Take a deep breath, it's evening",
					"Evening vibes only",
					"Let the stars guide you tonight"
				])
			} else if (hours >= 18) {
				return ([
					"Pack up and head home",
					"Have a safe way home",
					"Enjoy your dinner",
					 "Winner winner chicken dinner",
					"Time to prep for dinner",
					"Good job today",
					"Try again tomorrow",
					"Commuting after 6 pm? Stay safe",
					"Rush hour is over, relax now",
					"This greeting has been AI generated",
					"This greeting is human-made",
					"Almost home, hang in there",
					"End of the workday, time to unwind",
					"Leave work behind, enjoy your evening",
					"Time to switch to relaxation mode",
					"Your evening awaits, make it count",
					"Unwind after a long day",
					"Take a deep breath, you're almost there",
					"Home sweet home, almost there",
					"Your evening routine starts now",
					"Time to recharge for the next day",
					"Your daily grind is over, relax",
					"Unwind with a good book or a movie",
					"Let the evening calm you down",
					"Take a moment to appreciate the day",
					"Your evening, your time, enjoy",
					"Time to disconnect and recharge",
					"Your daily routine is done, relax",
					"Unwind with a warm cup of tea",
					"Let the evening soothe your mind",
					"Take a moment to reflect on the day",
					"Try again tomorrow"
				]);
			} else if (hours >= 15) {
				return ([
					"Time to slack a bit",
					"The job is almost done",
					"Nothing to do? Join discord.",
					"Work hard play hard",
					"Cubism!",
					"Here's some pixel art for you",
					"No 8bitdash no life",
					"A circle-free environment!",
					"Best rest is earned",
					"Good afternoon",
					"Time for a break",
					"Take a moment to relax",
					"Your afternoon pick-me-up",
					"Get ready for the final stretch",
					"Almost time to call it a day",
					"Keep pushing through the afternoon",
					"Stay focused, you're almost there",
					"Your afternoon motivation"
				])
			} else if (hours >= 12) {
				return ([
					"Enjoy your lunch time",
					"Lunch prep time",
					"Good job this morning",
					"0% sugar",
					"Try the stretch mode",
					"Wash your hands",
					"Don't be a dick",
					"You're cooking",
					"Try harder",
					"Power-up with lunch",
					"Prepare for the next level",
					"Level up this morning",
					"Pixel perfect health",
					"Sanitize your pixels",
					"Game on with kindness",
					"Level up your effort",
					"May the fork be with you",
					"Food coma incoming",
					"Time to refuel",
					"Your stomach (and coworkers) thank you",
					"Food for thought (and belly)",
					"Unleash your inner foodie",
					"Food coma mode activated",
					"Time to recharge your batteries (and belly)",
					"Your lunch break, your rules",
					"Foodie mode engaged",
					"Unwind with a sandwich",
					"Your lunchtime escape"
				]
				);
			}
		}
		// AM
		else {
			if (hours >= 9) {
				return ([
					"What a wonderful morning",
					"This is gonna be your day",
					"Greetings and salutations",
					"New day new dream",
					"Good morning community",
					"Good morning",
					"Rise and shine, it's coffee time",
					"Morning sunshine, you're looking bright",
					"Today's forecast: 100% chance of awesomeness",
					"Good morning, world! Let's do this",
					"Morning motivation: because adulting is hard"
				])
			} else if (hours >= 6) {
				return ([
					"The early bird catches the worm",
					"Rise and shine",
					"Another day another story",
					"Take it easy today",
					"You are early",
					"Time to get crackin'",
					"Let's make today egg-cellent",
					"Early to rise, early to shine",
					"Morning, sunshine!",
					"Up and at 'em!",
					"Time to seize the day",
					"Let's make today sunny side up",
					"Good morning, world!",
					"Time to get this bread",
					"Let's make today egg-citing",
					"Morning has broken",
					"Time to rise and grind",
					"Let's make today egg-ceptional",
					"Morning, beautiful!",
					"Time to start the day with a smile",
					"Let's make today egg-straordinary",
				])
			} else if (hours >= 3) {
				return ([
					"Time to go to bed",
					"Time to party real hard",
					"Dead-tired pixels",
					"Beware of ghosts",
					"Conscious in a dream",
					"Dreaming or awake",
					"Person behind you",
					"Awake or dreaming",
					"Stay a little more",
					"Witching hour",
					"Devil's hour",
					"Haunted hour",
					"REM active",
					"The day has 24 hours",
					"Taking on the night shift",
					"See you tonight space cowboy"])
			} else if (hours >= 0) {
				return ([
					"Sleep is still for the weak",
					"Your night shift just started",
					"Today is young",
					"Try the smooth mode",
					"See you tonight space cowboy",
					"Propose new greetings in Discord",
					"Midnight munchies are calling",
					"Time to level up your sleep game",
					"Who needs sleep when there's 8bitdash?",
					"Midnight is the new morning",
					"Your bed is calling, but so is the fridge"
				])
			}
		}
	}
	const greeting = () => {
		const items = greetings();
		const item = items[Math.floor(Math.random() * items.length)];
		return (item)
	}

	const [greetingState, setGreetingState] = useState(greeting());
	const style = {
		fontWeight: "800",
		fontSize: "0.24em", // golden ratio
	};
	const updateGreeting = () => {
		setGreetingState(greeting());
		setTimeout(updateGreeting, 1000 * 60 * 5);
	};
	useEffect(() => updateGreeting(), [])
	return (<div id="greeting" style={style}>{greetingState}</div>)
}
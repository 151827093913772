import { useState, useRef, useEffect } from "react";
import splashes from "./splashes";
import waifus from "./waifus";

const storage = window.localStorage;
// Saving loading properties 
export const save = (componentName, field, value) => {
	console.log("Saving", componentName, field, value);
	storage.setItem(componentName + "/" + field, value);
}
export const load = (componentName, field) => {
	console.log("Loading", componentName, field);
	return(storage.getItem(componentName + "/" + field));
}
export const loadOr = (componentName, field, orElse) => {
	const r = load(componentName, field) 
	return(r ? r : orElse);
}
export const toBool = (b) => b === 'true'

export function getRandomIntInclusive(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const randomSplash = (show_waifus = false) => {

  const totalPieces = Object.values(splashes).reduce((acc, artist) => acc + artist.length, 0);
  const randomPieceIndex = getRandomIntInclusive(0, totalPieces - 1);

  let currentPieceIndex = 0;
  var artistName;
  for (const [key, value] of Object.entries(splashes)) {
    if (currentPieceIndex + value.length > randomPieceIndex) {
      artistName = key;
      break;
    }
    currentPieceIndex += value.length;
  }

  const filenameIndex = randomPieceIndex - currentPieceIndex;
  const filename = splashes[artistName][filenameIndex];


  if(!show_waifus) { 
    const full_path  = artistName + "/" + filename
    if(waifus.includes(full_path)) {
      console.info("WAIFU FOUND- search for new background")
      return(randomSplash(show_waifus))
    } 
    else return({ folder: artistName, filename: filename })
  } else {
    return { folder: artistName, filename: filename }
  }
};

export const time = () => {
  const date = new Date();
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}



export default {
  "1041uuu": [
    "bridge.webp",
    "brightgirlcity.webp",
    "cattrain.webp",
    "coffeeshop.webp",
    "dogcatchase.webp",
    "japanbridge.webp",
    "japancars.webp",
    "japancity.webp",
    "japancityfall.webp",
    "japancityriver.webp",
    "japancityspring.webp",
    "japancitytall.webp",
    "japanfall.webp",
    "japanfishstore.webp",
    "japanresuturant.webp",
    "japanriver.webp",
    "japanrivergirl.webp",
    "japantrain.webp",
    "japanwarmbed.webp",
    "koi.webp",
    "kois.webp",
    "milk.webp",
    "peacefulgarden.webp",
    "plants.mp4",
    "plants2.mp4",
    "rainbow.webp",
    "raingyudon.webp",
    "ramen.webp",
    "sakura.webp",
    "schoolgirls.webp",
    "smallroom.webp",
    "soda.webp",
    "sushibar.webp",
    "techgirl.webp",
    "train.webp",
    "wintertrain.webp"
  ],
  "5ldo0on": [
    "dfgntlk-e99a3477-762b-4dc6-8c95-2ad93a573afd.gif",
    "dfkusgz-19c56cef-715a-4814-b7b7-a3f710ea40f4.gif",
    "dfng3qi-2ac91709-1729-4de6-9f52-4d69cd894460.gif",
    "dfpwt5g-e7ed244c-8c4e-429c-96ee-1c0f67fcc7d5.gif",
    "dfsnpe8-e7ef1be2-c8bf-48c6-9718-3bedfca3154e.gif",
    "dhu5089-5621b862-0942-4a5b-a462-1acd4edfc6f1.gif",
    "dig92c3-721ec0bb-10f6-40d3-a892-8eb13f9024fc.gif"
  ],
  "Haopanyo": [
    "15years.mp4",
    "90sarcade.mp4",
    "Attack-on-Titan.mp4",
    "Ben10.mp4",
    "Cyberpunk.mp4",
    "Game-boy.mp4",
    "Halloween.mp4",
    "Splatoon3.mp4",
    "Sushiii.mp4",
    "Teen-titans-TeenTitans.mp4",
    "TekkenBloodline.mp4",
    "VR-Arcade.mp4",
    "Xenoblade-3.mp4",
    "ZeldaTearsoftheKingdom.mp4",
    "arcade1.mp4",
    "badowls.mp4",
    "beach.mp4",
    "bossbattle.mp4",
    "catrobofighter1.mp4",
    "catrobofighter2.mp4",
    "chillinbirds.mp4",
    "cooking.mp4",
    "cutecollector.mp4",
    "cuteslimes.mp4",
    "demon.mp4",
    "dragon.mp4",
    "dragonquest.mp4",
    "famicom.mp4",
    "fishing.mp4",
    "gamer.mp4",
    "godbox.mp4",
    "goodbad.mp4",
    "grimreaper.mp4",
    "halloweengirl.mp4",
    "hamsters.mp4",
    "icecream.mp4",
    "japanesearcade.mp4",
    "japanesearcade2.mp4",
    "japanesestreet.mp4",
    "lazy-switch.mp4",
    "metroid.mp4",
    "metroid1.mp4",
    "mob-roasting.mp4",
    "mousegirl.mp4",
    "naruto.mp4",
    "onagirigirl.mp4",
    "owlgirl.mp4",
    "peng.mp4",
    "peng2.mp4",
    "persona3.mp4",
    "persona3reloaded.mp4",
    "pinkgirl.mp4",
    "pinkgirltree.mp4",
    "pirategirl.mp4",
    "pokemon-fight.mp4",
    "pokemon-running.mp4",
    "pokemon1.mp4",
    "pokemon2.mp4",
    "rabbit.mp4",
    "rayq.mp4",
    "roboGod.mp4",
    "rubysaphire.mp4",
    "ruincitygirl.mp4",
    "sharkgirl.mp4",
    "sheildgirl.mp4",
    "slimecity.mp4",
    "slimegirl2.mp4",
    "slimegirl3.mp4",
    "slimes.mp4",
    "snipergirl.mp4",
    "streetfighter.mp4"
  ],
  "archipics": [
    "deop0li-1d0b6f0f-16f0-4580-8721-868777fa2222.gif",
    "desgqkk-241d011f-7a40-41a8-b5b0-0b80c777a137.gif",
    "dfw6bqy-74554ae5-1f17-4c2f-8604-e615e2da0075.gif",
    "dga1hon-e847f7f4-d043-401b-8a12-835610161118.gif",
    "dgf1nfg-95de740f-6ce8-46d7-9e97-bee39a9bfb24.gif"
  ],
  "faxdoc": [
    "cacao_and_coffee_shop.gif",
    "comition_sky_left_to_right.gif",
    "flower_shop.gif",
    "lullaby.gif",
    "midnight_melancholy.gif",
    "mountain_mote.gif",
    "nero_land.gif",
    "sideshop.gif",
    "stacking_houses_on_a_windy_day.gif"
  ],
  "itzah": [
    "BotW.mp4",
    "CasteliaCity.mp4",
    "HD Desk 2b.mp4",
    "HD Desk Floaroma Town.mp4",
    "HD Desk Mythra.mp4",
    "HD Desk Peach sky (1).mp4",
    "HD Desk Pyra.mp4",
    "Hd Desk Nessa pool.mp4",
    "Majoras.mp4",
    "MasterSword.mp4",
    "OcarinanoUI.mp4",
    "OcarinawithUI.mp4",
    "PinwheelForest.mp4",
    "Skyward.mp4",
    "Tearsofkingdom.mp4",
    "Twilightprincess.mp4",
    "UndellaTown.mp4",
    "ZeldaMasterSword.mp4",
    "earthbound.mp4",
    "emerald.mp4",
    "emeraldbike.mp4",
    "hollowknight.mp4",
    "lapras.mp4",
    "maywaterfall.mp4",
    "peachsunshineHDUI.mp4",
    "resident1.mp4",
    "windwaker.mp4",
    "zeldasunset.mp4"
  ],
  "kirokaze": [
    "amp_prob.gif",
    "attack.gif",
    "bad_landing.gif",
    "bluebalcony.gif",
    "cemetry.gif",
    "cigarette.gif",
    "citymirror.gif",
    "coffeeinrain.gif",
    "dark_pillar.gif",
    "debee0l-eca2c69e-8c11-421b-905e-29841ab8bbf7.gif",
    "demggul-631572a3-f589-4f41-a710-2a8ad21ad7bd.gif",
    "dez5d9x-79cb89e0-a551-4731-82cd-399bbc6ea0c5.gif",
    "dezrmu4-0b463d5d-1c1f-463b-909b-6951227567a5.gif",
    "df1wdk7-fa62d5b6-a1b2-4b2b-b407-02e737476148.gif",
    "dfcu60j-004620a2-e00b-4497-93a3-b9ea738b6677.gif",
    "dfffuxf-dd7dbabc-ce26-46a1-be51-e98ba43094d8.gif",
    "dfvdbzc-ea86506e-08ec-4ce6-92c7-32ea7f5189f6.gif",
    "dfxag61-ffbc4931-94a6-4dba-bf8a-8e6a3546fabc.gif",
    "dfzzmsn-56a459df-0eab-4203-ba62-3da445bde6e5.gif",
    "dg3u7hq-c1a76bed-89de-4294-9621-bb4ec5928066.gif",
    "dgf7bs9-91a4de96-bb76-4a9c-8893-f8bb759fc027.gif",
    "dgneh1g-071d03bc-8b22-47e0-b11a-0024f0f3b50d.gif",
    "dh3gp8s-2ac87ac8-ac0a-4c7f-b901-5fbf4a57a3ed.gif",
    "droidcrime.gif",
    "elderorc.gif",
    "factory5.gif",
    "familydinner.gif",
    "holo.gif",
    "horse.gif",
    "iplayoldgames.gif",
    "last_dance.gif",
    "metro_final.gif",
    "nightlytraining.gif",
    "ontop.gif",
    "pilot.gif",
    "player2.gif",
    "reddriver.gif",
    "robot_alley.gif",
    "sandcastle.gif",
    "shootingstars.gif",
    "spacecommander.gif",
    "spaceport.gif",
    "thieves.gif",
    "train.gif",
    "train_city.gif",
    "transform.gif",
    "troll_cave.gif",
    "upload.gif",
    "upload2.gif",
    "wild_boy.gif",
    "windyday.gif",
    "youngatnight.gif",
    "zombies.gif"
  ],
  "mark_ferrari": [
    "bridge.gif",
    "bridge_raining.gif",
    "castle.gif",
    "cave.gif",
    "coast.gif",
    "dawn.gif",
    "falls.gif",
    "fire.gif",
    "forrest.gif",
    "fortress.gif",
    "grandcanyon.gif",
    "lake.gif",
    "mountain.gif",
    "nature.gif",
    "northlights.gif",
    "rain.gif",
    "sea.gif",
    "snow.gif",
    "swamp.gif",
    "swirling.gif",
    "temple.gif",
    "tower.gif",
    "town.gif",
    "underwater.gif"
  ],
  "minimoss": [
    "arrangement1.gif",
    "arrangement2.gif",
    "city.webp",
    "deer.webp",
    "happynewyear.gif",
    "jar.webp",
    "jumping-cat.webp",
    "lovers.webp",
    "moonthief.gif",
    "moonthief.webp",
    "ramen.webp",
    "sanctuary.webp",
    "shootingstars.webp",
    "slime.webp",
    "smallworld.gif",
    "storm.webp",
    "sword-broken.webp",
    "sword-fall.webp",
    "sword-forest.webp",
    "sword-grass.webp",
    "train.gif",
    "window.gif",
    "window.webp",
    "zengarden.webp",
    "zengarden2.webp"
  ],
  "muscat_dot": [
    "drivingcity.mp4",
    "floatingcity.mp4",
    "floatingerror.mp4",
    "girlonbed.mp4",
    "highschoollove.mp4",
    "schoollove.mp4",
    "tumblr_2e5480562e8e9b7b2333286ae6c8ddf9_db78cc80_1280.webp",
    "tumblr_42b58326fe6e6596885d6702f93aa343_63402ea9_1280.webp",
    "tumblr_4498c740f0a0afe2cd6bc732adf234e7_e071cf7c_1280.webp",
    "tumblr_4b2f2166057e939eb03e6c70895d117c_fab86841_640.webp",
    "tumblr_4cbb6fe7dde13f9db0f0d7800d12aa50_4059d25c_1280.webp",
    "tumblr_694cf25c17ff50c33f9e348d2822af97_aad42bec_1280.webp",
    "tumblr_72bc37a6dd2eea5d41da39f405b9b214_46ea4cd7_1280.webp",
    "tumblr_8a13f594591d9b8f8953727c723453ec_86d9364c_1280.webp",
    "tumblr_91c55ca328bcbaaef3a49f43e81f9899_e810cc62_1280.webp",
    "tumblr_bbde3d65e90f7e6923fcc06d29bd3f60_c4052dac_1280.webp",
    "tumblr_bd162c1e69424fcdba20e69f651ee396_0162cc9d_1280.webp",
    "tumblr_c4aa05c9b587fbd0f627861751e79bb7_35512d59_1280.webp",
    "tumblr_cf808051868b0b9d4761bf0ce0dad551_64fe004d_1280.webp",
    "tumblr_d9be6dd5ced68b8bf2182a5176ec4a30_48c7ac56_500.webp",
    "tumblr_efa37d990fa1b49d85ee405ac9befa2b_ccf23e9c_1280.webp",
    "tumblr_f374a8929ad38927ed982c55cb668864_33fbef5c_1280.webp",
    "tumblr_fae1d84082a4b430cd1a005f191a9177_c540959f_1280.webp"
  ],
  "nihao": [
    "dflpxpj-a261c065-fe1d-4525-8f8f-c4c7feb90ff2.gif"
  ],
  "pixeljeff": [
    "After-Rain.gif",
    "Alley-of-Wicked.gif",
    "Chill-Mando.gif",
    "Chill-of-the-Wild.gif",
    "Coding.gif",
    "Ditoo-Pro.gif",
    "Extraordinary-Attorney-Woo.gif",
    "First-Love.gif",
    "Good-Nap.gif",
    "Hungry-Bug.gif",
    "It-s-OK-to-be-Sad.gif",
    "Kirby-s-Chill-Land.gif",
    "Little-Pal.gif",
    "New-Dawn.gif",
    "Pixel-Jeff-X-Divoom.gif",
    "Playground.gif",
    "Prettiest-To-Me.gif",
    "animalcrossing.webp",
    "apartments.webp",
    "busstop.webp",
    "catstairs.webp",
    "chillboy.gif",
    "chillday.webp",
    "chillnap.webp",
    "christmas.webp",
    "coffeeshop.webp",
    "coldlove.webp",
    "datenight.webp",
    "ddr93fw-5011b54c-a4f3-425c-845b-177bac15db18.gif",
    "de3etbq-9dd90da3-c812-436f-a301-17a361e99af9.gif",
    "de3zz9z-ffa11131-1b4b-4e9f-8d01-aaddb1a14243.gif",
    "deavizl-1897a3ea-af30-4cd9-8cc8-ada2cad8cf12.gif",
    "dft4ktv-b678a9ff-8707-4e2f-a9d6-7a7f82cddf45.gif",
    "dg8b54p-18e32f29-9558-4fb9-83af-de94e437053a.gif",
    "dk.gif",
    "flower.gif",
    "futurepark.webp",
    "girlflood.webp",
    "hacker.webp",
    "halloween.webp",
    "halloweentwo.webp",
    "icecream.webp",
    "japanroom.webp",
    "lunarfestival.webp",
    "mariogamer.webp",
    "metroid.gif",
    "oldguyandtrash.webp",
    "rainyday.webp",
    "ramenshop.webp",
    "roadsidestore.webp",
    "rogfuture.webp",
    "sexy.webp",
    "shrine.webp",
    "spacegirl.gif",
    "starwars.webp",
    "storesleep.webp",
    "streetfood.webp",
    "videogame.webp",
    "wallcat.webp",
    "yoda.gif",
    "zombiethriller.webp"
  ],
  "tenten": [
    "blueflowers.webp",
    "cat.webp",
    "catWalking.webp",
    "forest.webp",
    "gameboy.webp",
    "napPark.webp",
    "schoolkids.webp",
    "slowTrain.webp",
    "summerHangers.webp",
    "summerpark.webp",
    "trainNight.webp",
    "washingdishes.webp"
  ],
  "unknown": [
    "2PM7Dvt.gif",
    "2VFm7VV.gif",
    "3YvpEVG.gif",
    "6ruJJm4.gif",
    "7lT24xG.gif",
    "8bFJd1z.gif",
    "90iMAqZ.gif",
    "9zwwI1r.gif",
    "AMkh5C9.gif",
    "Am2ShoD.gif",
    "E5vE8qb.gif",
    "HrfMaVr.gif",
    "JjCl6lo.gif",
    "L7clgTA.gif",
    "LktzhQZ.gif",
    "NVXo7jo.gif",
    "P9sq7WX.gif",
    "SHbEupX.gif",
    "UDRdIsM.gif",
    "a5p7ZSe.gif",
    "backdrop.gif",
    "bsGSRI2.gif",
    "ccc-bit.png",
    "chill.gif",
    "de8s253-90efbdf3-9090-4070-85d7-265996ed8c3a.gif",
    "fvYlDQY.gif",
    "hiPciv3.gif",
    "jFqjP6r.gif",
    "ka1Ilau.gif",
    "mxEK1Cs.gif",
    "nord_car_live.gif",
    "oeVSWxA.gif",
    "pHDU9n0.gif",
    "sPNNVoU.gif",
    "sQbUoCl.gif",
    "sUWLb3z.gif",
    "tumblr_1a73b1fa76536515f728037fb2ed1bbe_ff1a9011_500.webp",
    "tumblr_35ea285663dc28c401f54d73165041ae_5fd23a5c_500.webp",
    "tumblr_ab3b87a6b0a2582c0a8a379ddb37d849_71a1b5fb_500.webp",
    "tumblr_d9c4bca6bc650e33b346efaeb7b620aa_5849143e_500.webp",
    "tumblr_dfbe5326573ce78fa2bf6bb2f377d87f_5926cbe3_500.webp",
    "tumblr_fd1f4b85160a4cdd83c0a23d17ca6029_8b2a21fc_500.webp",
    "wallhaven-6krvpw.jpg",
    "wallhaven-g8e327.png",
    "wallhaven-g8eomq.jpg",
    "wallhaven-j5z6v5.png",
    "wallhaven-lm15jy.jpg",
    "wallhaven-oxrz6l.jpg",
    "xsMoGLV.gif",
    "xyFrj5w.gif",
    "zGBBoNj.gif",
    "ztu4D7t.gif"
  ],
  "valenberg": [
    "Wasteland-VirtuaVerse.gif",
    "bicycle.gif",
    "blade.gif",
    "controlroom.gif",
    "daftpunk.gif",
    "drift.gif",
    "echoesfromneals.gif",
    "exodus.gif",
    "future.gif",
    "girlinrain.gif",
    "highfloor.gif",
    "highlands.gif",
    "highsoceity.gif",
    "jazznight.gif",
    "lowlands.gif",
    "moon.png",
    "motorcycle.gif",
    "nighttrain.gif",
    "redbicycle.gif",
    "ride.gif",
    "shop.gif",
    "skate.gif",
    "streets.gif",
    "sushi.gif",
    "tv.gif",
    "virtuaverse.gif"
  ],
  "wakuwaku": [
    "japanafternoon.webp",
    "japanmorning.webp",
    "japannight.webp"
  ],
  "waneela": [
    "wallhaven-ympzv7.jpg"
  ],
  "waneella": [
    "01-26.gif",
    "01_mar_29.gif",
    "02_25.gif",
    "03-17.gif",
    "04_mar_04neud_24.gif",
    "1.gif",
    "18-35.gif",
    "19-23_smooth.gif",
    "20-48.gif",
    "23-37.gif",
    "35-6586935_139-6985097_waneella.gif",
    "35.6541693_139.7022296.gif",
    "35.6835709139.7030749.gif",
    "35.6913217139.7672178.gif",
    "3qnupLBQym.gif",
    "6USUVRm56F.gif",
    "Abyss_waneella.gif",
    "Afterglow_waneella.gif",
    "Air_waneella.gif",
    "Amber_waneella.gif",
    "Apr_01-17.gif",
    "Apr_04-24_clouds_13.gif",
    "Apr_05-21.gif",
    "Aug-04-35.gif",
    "August-01-17.gif",
    "Block_R-38_waneella.gif",
    "Bloom_waneella.gif",
    "Circles_waneella.gif",
    "Dark_Corners_waneella.gif",
    "Dawn_waneella.gif",
    "Destination_waneella.gif",
    "Dim_waneella.gif",
    "Distance_waneella.gif",
    "Distorted_waneella.gif",
    "Edge_waneella.gif",
    "Empty_Spaces_waneella.gif",
    "Evening_forecast_waneella.gif",
    "Feb_03_29.gif",
    "Fragments_waneella.gif",
    "Full_of_Hopes_waneella.gif",
    "GUNSHIP.gif",
    "Harbour_waneella.gif",
    "Haze_waneella.gif",
    "Heat_waneella.gif",
    "Horizon_Pt-1_waneella.gif",
    "Horizon_Pt-2_waneella.gif",
    "June-03-31.gif",
    "June_01_full_1600_05.gif",
    "Lights_waneella.gif",
    "Looks_Like_A_Nice_Place_Pt-1_waneella.gif",
    "Looks_Like_A_Nice_Place_Pt-2_waneella.gif",
    "Lull_waneella.gif",
    "May_01-16.gif",
    "May_02-31_FIN.gif",
    "Maybe_We_Should_Stay_Here-waneella.gif",
    "Multiverse_waneella.gif",
    "N-19_waneella.gif",
    "Nice_View_waneella.gif",
    "Night_waneella.gif",
    "No-Sleep-Again_waneella.gif",
    "Old-Town_waneella.gif",
    "On-The-Hill_waneella.gif",
    "Pearls_waneella.gif",
    "Pieces_waneella.gif",
    "Return_waneella.gif",
    "Rose_Waves_waneella.gif",
    "Same_Way_waneella.gif",
    "Sep-05-26-06.gif",
    "Sep-06-22.gif",
    "Shadow_waneella.gif",
    "Sketch_3_color-22.gif",
    "Solstice_waneella.gif",
    "Stardust_waneella.gif",
    "Summer_thoughts_waneella.gif",
    "Sunshine_waneella.gif",
    "The-Last-Hour_04.gif",
    "This_One_Still_Works_waneella.gif",
    "Undertone_waneella.gif",
    "Warm_Days_Pt-1_waneella.gif",
    "Warm_Days_Pt-2_waneella.gif",
    "XP-pen-review-artwork-waneella.gif",
    "Zephyr_waneella.gif",
    "blue_waneella_03.gif",
    "city4.gif",
    "glasscube.gif",
    "heartbeat.gif",
    "j0P563I4ZE.gif",
    "mbRtfWLicq.gif",
    "rARIVxe6GC.gif",
    "radient.gif",
    "solar_wind_waneella.gif",
    "waneella_198X_no_text_1440x1620_twitter.gif",
    "waneella_dusk.gif"
  ],
  "yaleiSyu": [
    "Girl_sleeping.gif",
    "adventure_fireplace.gif",
    "animegirl_fight.gif",
    "aquarium.gif",
    "beach_chillin.gif",
    "butterfly_garden.gif",
    "cafe_girl.gif",
    "cat_desk.gif",
    "cat_guitar.gif",
    "cat_stand.gif",
    "chillroom.gif",
    "christmas_couple1.gif",
    "christmas_couple2.gif",
    "christmas_couple3.gif",
    "christmas_couple_fireplace.gif",
    "city_overlook.gif",
    "cooking_show.gif",
    "cute_cafe.gif",
    "demon_alter.gif",
    "dogs.gif",
    "dragon_friends.gif",
    "easter_couple.gif",
    "eve_fireplace.gif",
    "fireworks_couple.gif",
    "flower_girl.gif",
    "frieren1.gif",
    "frieren2.gif",
    "girl_bar.gif",
    "girl_bus.gif",
    "girl_cafe.gif",
    "girl_computer.gif",
    "girl_computer2.gif",
    "girl_forest.gif",
    "girl_melonsoda.gif",
    "girl_room.gif",
    "girl_walking.webp",
    "girl_workshop.gif",
    "halloween_couple.gif",
    "halloweengirl.gif",
    "hotgirl_garden.gif",
    "ipad_girl.gif",
    "japanesestore.gif",
    "loading.gif",
    "magicbook.gif",
    "man_fox.gif",
    "mermaid.gif",
    "mushrooms.gif",
    "night_walk.gif",
    "paint_couple.gif",
    "pirates.gif",
    "rain_girl.gif",
    "red_car.gif",
    "sleepingforest.gif",
    "taiwan_conbini.gif",
    "vampgirls.gif"
  ]
};
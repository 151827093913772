import React, { useState, useEffect, useRef } from 'react';

export default function Dialog({ textToDisplay = "no text given", onClose }) {
	const dialogStyle = {
        backgroundColor: "black",
        opacity: 0.9, // Set initial opacity to 0 for fade in effect
		height: "7em", // Ensured minimum height of 10% of the overall window
        zIndex: 0,
        position: "fixed",
        width: "32em", // Ensured minimum width of 25% of the overall window
        bottom: "5%", // Moved to bottom
        left: "2%", // Moved to left with padding
        padding: "20px", // Increased padding
        border: "2px solid #000", // Added white grayish line border
        display: "flex",
        flexDirection: "row",
		
	};
	const avatarStyle = {
        width: "7em", // Adjusted to maintain 1:1 aspect ratio
        height: "7em", // Adjusted to maintain 1:1 aspect ratio
        backgroundImage: "url('bitty/glitched3.gif')",
        backgroundSize: "cover",
        marginRight: "1em",
        aspectRatio: 1, // Added to maintain 1:1 aspect ratio
	};
	const textStyle = {
        width: "60%",
        height: "100%",
        overflowY: "auto",
        fontSize: "17px", // Adjusted font size for better readability
	};
	const closeButtonStyle = {
        position: "absolute",
        top: "10px",
        right: "10px",
        padding: "5px",
        cursor: "pointer",
        color: "white",
        fontSize: "16px", // Adjusted font size to match the text inside the dialog
        textDecoration: "none", // Initially set to none
	};
	const [text, setText] = useState('');
	const [isHovered, setIsHovered] = useState(false); // Added state to track hover

	useEffect(() => {
		let index = 0;
		const interval = setInterval(() => {
			setText(textToDisplay.slice(0, index + 1));
			index = Math.min(index + 1, textToDisplay.length);
			if (index === textToDisplay.length) {
				clearInterval(interval);
			}
		}, 10 + 20 * (index +1)); // Exponentially smoothed, getting slower at the end
		return () => clearInterval(interval);
	}, [textToDisplay]);

	useEffect(() => {
		const handleKeyPress = (event) => {
			if (event.key !== "Escape") {
				onClose();
			}
		};
		window.addEventListener('keydown', handleKeyPress);
		return () => {
			window.removeEventListener('keydown', handleKeyPress);
		};
	}, [onClose]);

	return (
		<div style={dialogStyle}>
			<a style={{...closeButtonStyle, textDecoration: isHovered ? "underline" : "none"}} // Modified to conditionally apply underline
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
				onClick={() => onClose()}
			>[x]</a>
			<div style={avatarStyle}></div>
			<div style={textStyle}>
				{text}
			</div>
		</div>
	);
};

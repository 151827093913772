import { useState, useRef, useEffect } from "react";
import { useTweaks, makeButton } from "use-tweaks";

import "./App.css";
import { loadOr, save, toBool, randomSplash, time, useWindowSize } from "./Utility";
import Clock from "./Clock";
import { Bar, TopBar } from "./Bars";
import Dialog from "./Dialog";

import Plausible from 'plausible-tracker';

const { trackPageview, trackEvent, enableAutoOutboundTracking } = Plausible({
	domain: '8bitdash.com',	
	trackLocalhost: true
})

enableAutoOutboundTracking();
trackPageview();

const NEW_MESSAGE = "A fresh 2025, everyone :) Wish you all love, health and happiness! <3 stay tuned for some cool updates from 8bd";

function App() {
	const ref = useRef(null);
	const size = useWindowSize();
	const tweaks = useTweaks("app", {
		clock: toBool(loadOr("app", "clock", "true")),
		smooth: toBool(loadOr("app", "smooth", "false")),
		stretch: toBool(loadOr("app", "stretch", "false")),
		grayscale: toBool(loadOr("app", "grayscale", "false")),
		sepia: toBool(loadOr("app", "sepia", "false")),
		show_waifus: toBool(loadOr("app", "show_waifus", "false")),
		font_i: { value: parseFloat(loadOr("app", "font_i", "0")), min: 0, max: 4 },
		...makeButton("Change splash", () => setSplash(randomSplash())),
		...makeButton("Hide tweaks", () => {
			setShowTweaks(false);
			trackEvent('close-tweaks', {
			callback: () => console.log("close tweaks logged.")
		})})
	});

	const [splash, setSplash] = useState(null);
	const [font, setFont] = useState("minecraftfont");
	const [showTweaks, setShowTweaks] = useState(toBool(loadOr("app", "tweaks", "false")));

	const saved_last_message = loadOr("app","last_message","")

	const [showDialog, setShowDialog] = useState(false)//(saved_last_message != NEW_MESSAGE));
	const [message, setMessage] = useState(NEW_MESSAGE);

	const fonts = ["minecraftfont", "print21", "Inter"];

	const backdropUrl = splash
		? "splash/" + splash.folder + "/" + splash.filename
		: "";

	const filters = () => {
		var applied = []
		if (tweaks.grayscale) {
			applied.push("grayscale(1.0)");
		}
		if (tweaks.sepia) {
			applied.push("sepia(1.0)");
		}
		if (applied.length > 0) {
			return (applied.join(" "))
		} else {
			return ("none");
		}
	}

	const appStyle = {
		fontFamily: font,
		color: "white",
		height: size.height + "px",
		width: size.width + "px",
		filter: filters()
	};

	useEffect(() => {
		setSplash(randomSplash(tweaks.show_waifus));
	}, []);
	useEffect(() => {
		ref.current.style.backgroundSize = tweaks.stretch ? "100% 100%" : "cover"
	}, [appStyle]);
	useEffect(() => {
		setFont(fonts[Math.floor(tweaks.font_i)]);
	}, [tweaks.font_i]);

	useEffect(() => {
		const el = document.getElementsByClassName("tp-dfwv")[0];
		el.hidden = !showTweaks;
		save("app", "tweaks", showTweaks)
	}, [showTweaks]);
	useEffect(() => {
		Object.keys(tweaks).forEach(k => {
			save("app", k, tweaks[k])
		})
	}, [tweaks])

	const renderBackground = () => {
		if (backdropUrl.endsWith(".mp4")) {
			return (
				<video autoPlay="true" loop style={{
					position: "fixed",
					right: 0,
					bottom: 0,
					minWidth: "100%",
					minHeight: "100%"
				}} src={backdropUrl}>
					<div ref={ref} ></div>
				</video>
			)
		} else {
			return (<div ref={ref} style={{
				background: "url(" + backdropUrl + ") no-repeat",
				backgroundSize: "cover",
				imageRendering: tweaks.smooth ? "auto" : "crisp-edges",
				height: size.height + "px",
				width: size.width + "px",
				zIndex: 1,
			}} className="image_backdrop" />)
		}
	}

	// if tweaks is hidden, then display the top bar
	return (
		<div style={appStyle} className="App">
			{renderBackground()}
			{!showTweaks && <TopBar showSettings={() => {
				setShowTweaks(true);
				trackEvent('show-tweaks', {
					callback: () => console.log("tweak event logged.")
				})
			}} />}
			{tweaks.clock ? <Clock /> : null}
			{showDialog ? <Dialog onClose={() => {
				setShowDialog(false); 
				trackEvent('close-dialog', {
					callback: () => console.log("close dialog logged.")
				})
				save("app","last_message",NEW_MESSAGE)
			}} textToDisplay={message}></Dialog> : null}
			<Bar splash={splash} fontName={font} />
		</div>
	);
}

export default App;
